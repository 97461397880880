import E from "./Editable.module.css";
import C from "./WSelect.module.css";

import type { AriaLabelingProps, FocusableDOMProps } from "@react-types/shared";
import { mapFrom } from "@warrenio/utils/collections/maps";
import {
    Fragment,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
    type PropsWithChildren,
    type ReactNode,
} from "react";
import {
    Button,
    ButtonContext,
    FieldError,
    Header,
    ListBox,
    ListBoxItem,
    ListBoxSection,
    Popover,
    Select,
    SelectValue,
    type Key,
    type ListBoxProps,
    type SectionProps,
    type SelectProps,
} from "react-aria-components";
import invariant from "tiny-invariant";
import { TODO } from "../../dev/Todo.tsx";
import { isTodoFn } from "../../dev/todoFn.ts";
import { mcn, type BaseProps } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";
import { useControlledState } from "../../utils/react/useControlledState.tsx";
import { usePromiseLoading } from "../../utils/react/usePromiseLoading.ts";
import { WButton } from "../button/WButton.tsx";
import { emptyRecordsText } from "../empty.tsx";
import { MaskIcon } from "../icon/MaskIcon.tsx";
import { WSearchField } from "./WSearchField.tsx";

/** Properties that only control the base style of WSelect */
export interface WSelectStyleProps
    extends Pick<
            SelectProps<object>,
            // Inherited props
            "defaultOpen" | "isRequired" | "isOpen" | "isDisabled" | "isInvalid" | "autoFocus"
        >,
        AriaLabelingProps,
        FocusableDOMProps {
    // Classnames
    className?: string;
    itemClassName?: string;
    valueClassName?: string;

    // Rendering
    /** Display empty sections (when eg. searching) */
    showEmptySections?: boolean;
    /** Text to show when no value has been selected */
    placeholder?: string;
    /** Custom component to show after the items list */
    footer?: ReactNode;
    /** Do not use a Popover, instead render the contents directly after the select */
    storyView?: boolean;
    autoFocusSearch?: boolean;

    // Inline editing
    enableInlineEditing?: boolean;
    isReadOnly?: boolean;

    // Status
    onOpenChange?: (isOpen: boolean) => void;
    isLoading?: boolean;
    isDataError?: boolean;
    isReferral?: boolean;
}

/** Properties that control the selected value of WSelect (useful to inherit from in wrapper component properties) */
export interface WSelectValueProps<TItem, TKey extends Key> {
    // Selection state
    value?: TItem;
    /** `null` means nothing is selected */
    valueKey?: TKey | null;

    defaultValue?: TItem;
    /** `null` means nothing is selected */
    defaultValueKey?: TKey | null;

    /** Function to pick a default among the available items, if no default value was provided. */
    dynamicDefault?: (items: readonly TItem[]) => TItem | undefined;
    onChange?: (item: TItem, id: TKey) => void | Promise<void>;

    // Filtering
    isItemDisabled?: (item: TItem) => boolean;
}

interface WSelectBaseProps<TItem, TKey extends Key> extends WSelectStyleProps, WSelectValueProps<TItem, TKey> {
    getKey: (item: TItem) => TKey;
    getTextValue?: (item: TItem) => string;

    // Filtering
    disabledKeys?: readonly TKey[];
    /** NB: This function should ONLY filter according to query. Do not put non-search filters here or the search box will be shown unnecessarily. */
    searchItems?: ((query: string, items: readonly TItem[]) => readonly TItem[]) | true;

    // Rendering
    emptyItem?: ReactNode;
    children: (item: TItem) => ReactNode;
    renderSelectedValue?: (item: TItem) => ReactNode;

    // Inline editing
    /**
     * {@link item} is `undefined` if no item has been selected yet.
     */
    viewer?: (item: TItem | undefined) => ReactNode;

    // Define custom unknown item, otherwise generic "Missing" text is displayed
    renderUnknown?: (valueKey: TKey | null) => ReactNode;
}

/** A select with items */
export interface WSelectProps<TItem, TKey extends Key> extends WSelectBaseProps<TItem, TKey> {
    items: readonly TItem[];
    sections?: never;
}

/** A select with sections (containing items) */
export interface WSelectSectionsProps<TItem, TKey extends Key> extends WSelectBaseProps<TItem, TKey> {
    items?: never;
    sections: readonly SectionWithItems<TItem>[];
}

/** Properties useful for passing into a predefined select (ie. one with item rendering already configured) */
export interface ConfiguredWSelectProps<TItem, TKey extends Key>
    extends WSelectValueProps<TItem, TKey>,
        WSelectStyleProps,
        Pick<WSelectProps<TItem, TKey>, "items"> {}

/**
 * Represents a section in a {@link WSelect}.
 *
 * @see {@link WSelectSectionsProps}
 */
export interface SectionWithItems<TItem> {
    /**
     * Arbitrary component to render as the section.
     * Should contain a {@link WSectionItems} component to show the items, and possibly a {@link WSectionHeader} to show a title.
     */
    component: ReactNode;
    /** React key */
    key: number | string;

    items: readonly TItem[];
}

function WSelectListBox<T extends object>({ isEmpty, ...props }: { isEmpty: boolean } & ListBoxProps<T>) {
    const ref = useRef<HTMLDivElement>(null);

    // Freeze the listbox height once it is open, to prevent it from jumping around if the number of items changes eg.
    // when searching.
    useEffect(() => {
        const el = ref.current;
        const targetHeight = el?.clientHeight;
        if (!targetHeight || isEmpty) {
            // console.debug("Not freezing listbox height: %o", { targetHeight, isEmpty });
            return;
        }

        el.style.height = `${targetHeight}px`;
        console.debug("Froze listbox height to %s", el.style.height);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ListBox<T>
            ref={ref}
            className={cn(C.WSelectListBox, isEmpty && C.empty)}
            selectionMode={isEmpty ? "none" : "single"}
            {...props}
        />
    );
}

/** Context that contains the items for the currently rendering section */
const SectionItemsContext = createContext<ReactNode[] | undefined>(undefined);

export function WSelect<TItem, TKey extends Key>({
    className,
    itemClassName,
    valueClassName,
    storyView = false,
    autoFocusSearch,

    items: itemsProp,
    sections,
    showEmptySections = false,

    getKey,
    getTextValue,

    value: controlledValue,
    valueKey: controlledValueKey,
    defaultValue,
    defaultValueKey,
    dynamicDefault,
    onChange,

    disabledKeys = [],
    isItemDisabled,
    searchItems,
    children,
    renderSelectedValue,

    emptyItem = <EmptyItem />,
    footer,

    enableInlineEditing,
    isOpen: controlledIsOpen,
    defaultOpen,
    isReadOnly,
    viewer,
    renderUnknown,
    placeholder,

    isLoading: isLoadingProp,
    isDataError = false,
    isDisabled = false,
    onOpenChange,
    isReferral = false,
    ...props
}: WSelectProps<TItem, TKey> | WSelectSectionsProps<TItem, TKey>) {
    /** Get a key for an item, if it is not undefined */
    function getOptionalKey(item: TItem | undefined) {
        return item !== undefined ? getKey(item) : undefined;
    }

    invariant(itemsProp != null || sections != null, "WSelect: Either `items` or `sections` must be provided");

    // Convert sections to items and vice versa
    sections ??= [{ component: <WSectionItems />, key: "__defaultSection__", items: itemsProp! }];
    const allItems = sections.length === 1 ? sections[0].items : sections.flatMap((section) => section.items);

    //#region Hooks

    const [query, setQuery] = useState<string>("");

    const [isOpen, setOpen] = useControlledState(controlledIsOpen, defaultOpen ?? false, onOpenChange);

    const { isPromiseLoading, registerPromise } = usePromiseLoading();

    const [valueKey, setValueKey] = useControlledState(
        controlledValueKey !== undefined ? controlledValueKey : getOptionalKey(controlledValue),
        defaultValueKey !== undefined ? defaultValueKey : (getOptionalKey(defaultValue) ?? null),
        (key: TKey) => {
            // Automatically set the "loading" state when `onChange` returns a promise
            registerPromise(onChange?.(keyedItems.get(key)!, key));
        },
    );

    // Figure out the actual default value (if `dynamicDefault` is provided)
    const newDefaultKey = useMemo(() => {
        // Skip the dynamic default when:
        //  - A value has already been provided.
        //  - Inline editing is enabled, because selecting a default value would automatically submit it.
        if (valueKey == null && dynamicDefault && !enableInlineEditing && allItems.length > 0) {
            const newDefault = dynamicDefault(allItems);
            if (newDefault !== undefined) {
                return getKey(newDefault);
            }
        }
        return undefined;
    }, [valueKey, dynamicDefault, allItems, getKey, enableInlineEditing]);

    const keyedItems = mapFrom(allItems, (item) => getKey(item));

    const lastDynamicDefault = useRef<{ key?: TKey; count: number }>({ key: undefined, count: 0 });

    // Update the value if a dynamic default is available (and send the change event)
    useEffect(() => {
        if (newDefaultKey !== undefined && newDefaultKey !== valueKey) {
            const { current } = lastDynamicDefault;
            const { key, count } = current;

            let canUpdate;
            if (newDefaultKey !== key) {
                canUpdate = true;
                current.key = newDefaultKey;
                current.count = 0;
            } else if (count <= 3) {
                // NB: Allow more than 1 duplicate update, due to RHF bug where the field value is not updated on the first try
                canUpdate = true;
                current.count++;
            } else {
                // NB: Concurrent rendering in React seems to lead to an edge case where, for controlled components,
                // multiple re-renders are triggered without the value prop being updated, causing dynamic defaults to
                // go into an infinite loop. Work around this by only triggering the default once.
                canUpdate = false;
                console.warn("WSelect dynamic default: %o already triggered", newDefaultKey);
            }

            if (canUpdate) {
                console.debug("WSelect dynamic default: %o <- %o", newDefaultKey, valueKey);
                setValueKey(newDefaultKey);
            }
        }
    }, [newDefaultKey, valueKey, setValueKey]);

    const value = valueKey !== null ? keyedItems.get(valueKey) : undefined;
    /** Unknown, ie. such a key does not exist among the provided items */
    const valueIsUnknown = valueKey !== null ? value === undefined : false;

    //#endregion Hooks

    const isLoading = isLoadingProp ?? isPromiseLoading;

    placeholder ??= isLoading ? "Loading..." : isDataError ? "Data missing" : "Select an option";

    if (enableInlineEditing && !isOpen) {
        const genericMissingNode = <span className="text-error">Missing</span>;
        return (
            <div className={E.Editable}>
                <WButton
                    variant="editable"
                    icon={isReadOnly ? undefined : "jp-icon-edit"}
                    iconSide="right"
                    isDisabled={isDisabled || isReadOnly}
                    isLoading={isLoading}
                    action={() => setOpen(true)}
                >
                    {valueIsUnknown
                        ? renderUnknown
                            ? renderUnknown(valueKey)
                            : genericMissingNode
                        : viewer
                          ? viewer(value)
                          : value !== undefined
                            ? getTextValue?.(value)
                            : placeholder}
                </WButton>

                {isTodoFn(onChange) && <TODO small />}
            </div>
        );
    }

    // console.debug("WSelect", { value, valueKey, defaultValue, defaultValueKey });

    //#region Search
    const minItemsForSearch = import.meta.env.DEV ? 4 : 8;
    const hasSearch = searchItems && allItems.length >= minItemsForSearch;

    invariant(searchItems !== true || getTextValue, "`getTextValue` must be provided when `searchItems` is `true`");

    let filteredSections = sections;
    if (hasSearch && query !== "") {
        let searchFn = searchItems;
        if (searchFn === true) {
            searchFn = (query, items) =>
                items.filter((item) => getTextValue!(item).toLowerCase().includes(query.toLowerCase()));
        }

        filteredSections = sections.map((section): typeof section => ({
            ...section,
            items: searchFn(query, section.items),
        }));
    }
    //#endregion

    const listBoxChildren = filteredSections
        .filter(({ items }) => showEmptySections || items.length > 0)
        .map(({ component, key, items }) => {
            const sectionItems = items.map((item) => {
                const key = getKey(item);
                return (
                    <ListBoxItem
                        key={key}
                        id={key}
                        textValue={getTextValue?.(item)}
                        className={cn(C.WSelectListBoxItem, itemClassName)}
                    >
                        {children(item)}
                    </ListBoxItem>
                );
            });

            return (
                <SectionItemsContext.Provider value={sectionItems} key={key}>
                    {component}
                </SectionItemsContext.Provider>
            );
        });

    if (isItemDisabled != null) {
        const allFilteredItems = filteredSections.flatMap((section) => section.items);
        disabledKeys = [...disabledKeys, ...allFilteredItems.filter(isItemDisabled).map(getKey)];
    }

    const isEmpty = !listBoxChildren.length;
    if (isEmpty) {
        // NB: There should be at least one item in the listbox, otherwise it will not open (so insert an "empty item" placeholder)
        disabledKeys = [...disabledKeys, emptyKey as TKey];
        listBoxChildren.push(<Fragment key={emptyKey}>{emptyItem}</Fragment>);
    }

    const focusTarget = hasSearch && autoFocusSearch ? "search" : !isEmpty ? "list" : null;

    const contents = (
        /* NB: An empty `ButtonContext.Provider` is used to prevent any buttons
        inside here from becoming popover triggers automatically (due to `Select`) */
        <ButtonContext.Provider value={undefined}>
            {hasSearch && (
                <Header className={C.WSelectListBoxHeader}>
                    <WSearchField value={query} onChange={setQuery} autoFocus={focusTarget === "search"} />
                </Header>
            )}

            <WSelectListBox isEmpty={isEmpty} autoFocus={focusTarget === "list"}>
                {listBoxChildren}
            </WSelectListBox>

            {footer && <Header className={C.WSelectListBoxFooter}>{footer}</Header>}
        </ButtonContext.Provider>
    );

    return (
        <Select
            aria-label={placeholder}
            {...props}
            isOpen={isOpen}
            className={cn(
                className,
                C.WSelect,
                enableInlineEditing && C.editable,
                enableInlineEditing && E.Editable,
                isReferral && C.Referral,
            )}
            selectedKey={valueKey}
            onSelectionChange={(ariaKey) => {
                const key = ariaKey as TKey;
                console.debug("WSelect onSelectionChange: %o", key);

                if (enableInlineEditing) {
                    setOpen(false);
                }
                setValueKey(key);
            }}
            disabledKeys={disabledKeys}
            isDisabled={isDisabled ? true : isLoading || isDataError}
            placeholder={placeholder}
            onOpenChange={(open) => {
                if (!open) {
                    // NB: Clearing the filter is very important, as react-aria does not allow opening a Select if it has no items
                    setQuery("");
                }

                setOpen(open);
            }}
        >
            <Button className={C.WSelectButton}>
                {renderSelectedValue ? (
                    <SelectValue className={cn(C.WSelectValue, valueClassName)}>
                        {({ isPlaceholder }) => (isPlaceholder ? placeholder : renderSelectedValue(value!))}
                    </SelectValue>
                ) : (
                    <SelectValue className={cn(C.WSelectValue, valueClassName)} />
                )}
                <div className={C.WSelectCaret}>
                    <MaskIcon className="jp-icon-caretdown size-0.875rem" />
                </div>
            </Button>
            <FieldError className={C.WSelectError} />

            {storyView ? (
                contents
            ) : (
                <Popover className={cn(C.WSelectPopover, enableInlineEditing && C.EditablePopover)} offset={0}>
                    {contents}
                </Popover>
            )}
        </Select>
    );
}

//#region Empty item

export const emptyKey = "__empty__";

export const EmptyItem = ({ children = emptyRecordsText, textValue }: { children?: ReactNode; textValue?: string }) => (
    <ListBoxItem
        id={emptyKey}
        className={cn(C.WSelectListBoxItem, "text-muted")}
        textValue={textValue ?? (typeof children === "string" ? children : undefined)}
    >
        {children}
    </ListBoxItem>
);

//#endregion

//#region Sections

export interface WSectionProps extends Omit<SectionProps<undefined>, "children" | "items"> {
    children: ReactNode;
}

export function WSection(props: WSectionProps) {
    return <ListBoxSection {...props} />;
}

export interface WHeaderProps extends PropsWithChildren<BaseProps> {}

export function WSectionHeader(props: WHeaderProps) {
    return <Header {...mcn(C.WSectionHeader, props)} />;
}

export function WSectionItems() {
    const items = useContext(SectionItemsContext);
    return items;
}

//#endregion
