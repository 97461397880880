import type { Merge, SetOptional } from "type-fest";
import { cn } from "../../utils/classNames.ts";
import { WSelect, type WSelectProps } from "../forms/WSelect.tsx";

export interface SimpleItem<T> {
    value: T;
    label?: string;
    key?: string;
}

// eslint-disable-next-line react-refresh/only-export-components
export function toSimpleItem<T>(value: T): SimpleItem<T> {
    return { value };
}

/**
 * Version of {@link WSelect} suitable for eg. selecting from a list of strings without a lot of boilerplate.
 *
 * Contains {@link SimpleItem}s.
 */
export function WSimpleSelect<T>({
    itemClassName,
    placeholder,
    value,
    items,
    onChange,
    ...props
}: Merge<
    SetOptional<WSelectProps<SimpleItem<T>, string>, "children" | "getKey">,
    {
        value?: T;
        items: readonly SimpleItem<T>[];
        onChange: (value: T) => Promise<void> | void;
    }
>) {
    type TI = SimpleItem<T>;

    const { children } = props;

    function itemToText({ value }: TI) {
        return value === undefined ? "Default" : typeof value === "string" ? value : JSON.stringify(value);
    }

    function getTextValue(item: TI): string {
        return item.label ?? itemToText(item);
    }

    function getKey({ key, value }: TI): string {
        return (key ?? value === undefined) ? "undefined" : JSON.stringify(value);
    }

    const selected = items.find((item) => item.value === value);
    // logExpandedObject("SimpleSelect", { selected, value, items });

    return (
        <WSelect
            itemClassName={cn("HStack items-center", itemClassName)}
            aria-label="Select an option"
            // eslint-disable-next-line react/no-children-prop
            children={(item) => getTextValue?.(item)}
            valueKey={selected ? getKey(selected) : null}
            items={items}
            getKey={getKey}
            getTextValue={getTextValue}
            placeholder={placeholder}
            renderSelectedValue={
                children === undefined
                    ? // Always render the placeholder when an `undefined` value is selected
                      (item) => (item?.value === undefined ? placeholder : getTextValue(item))
                    : undefined
            }
            onChange={(item) => onChange?.(item?.value)}
            searchItems
            {...props}
        />
    );
}
